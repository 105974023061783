<template>
  <div id="Reports">
    <div class="columns is-multiline">
      <button class="button is-loading ml-3 is-fullwidth is-large" v-if="loading"></button>
      <div class="column is-3" v-for="report in reports" :key="report">
        <div class="is-shadowless">
          <button @click="openReport(report)" class="button is-default is-fullwidth mt-4">{{ report.label }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Reports',
  components: { },
  data() {
    return {
      loading: false,
      reports: [],
      t: this.$store.getters["Translations/getTranslations"](
          this.$options.name
      ),
    };
  },
  created() {
    this.getReports();
  },
  methods: {
    async getReports() {
      this.loading = true;
      try {
        let data = await this.$store.dispatch("dataviewKit/getViewData", {
          view: "cm_incaze_nl_all_reports",
          paginated: false,
          itemsPerPage: 25,
        });

        if (!data.success) {
          throw data;
        }
        this.reports = data.data;
        console.log(data);
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    openReport(report) {
      this.$router.push({
        name: 'Report',
        params: {
          report_id: report.report,
        }
      })
    }
  }
};
</script>
